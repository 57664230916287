import Hero from '../HeroSection/Hero';
import About from '../About/About';
import PS from '../PreferredSectors/PreferredSectors';
import PO from '../ProgramOfferings/ProgramOfferings';
import WCA from '../WCA/WCA';
import Timeline from '../Timeline/Timeline';
import Partners from '../Partners/Partners';
import Footer from '../Footer/Footer';
import './TT.css';
import { motion } from 'framer-motion';


function TT() {
  return (
    <div className="Home">
      <div className="tt-about">
        <div className="tt-about-img-sec">
          <motion.img src="assets/img/tt_logo_alt.png" alt=""
            initial={{ opacity: 0, filter: "blur(20px)" }}
            whileInView={{ opacity: 1, filter: "blur(0px)" }}
            transition={{ duration: 0.5, ease: 'easeIn' }}
          />
          <motion.h1
            initial={{ opacity: 0, filter: "blur(20px)" }}
            whileInView={{ opacity: 1, filter: "blur(0px)" }}
            transition={{ duration: 0.5, ease: 'easeIn' }}
          >Deep dive into insights of Tourism Industry</motion.h1>
          <motion.button
            initial={{ opacity: 0, filter: "blur(20px)" }}
            whileInView={{ opacity: 1, filter: "blur(0px)" }}
            transition={{ duration: 0.5, ease: 'easeIn' }}
          ><a href='http://bit.ly/AIC-TT24' target='_blank'>Register Now</a></motion.button>
        </div>
        <div className="tt-about-content-sec">
          <motion.div className="tt-about-inner-sec"
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2, ease: 'easeIn' }}
          >
            <h1>About</h1>
            <p>
              Tourism Talks is a 4-week webinar series for Tourism Enthusiasts, focusing on different facets of
              the tourism industry such as prevailing challenges, potential solutions, possible opportunities
              &amp; success stories in the tourism domain.
            </p>
          </motion.div>
        </div>
      </div>


      <motion.div className="tl"
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
      >
        <h1>Schedule</h1>
        <div className="tt-tl-content">
          <section class="tt-design-section" style={{ zIndex: "2" }}>
            <div class="tt-timeline">
              <div class="timeline-empty">
              </div>
              <div class="timeline-middle">
                <div class="timeline-circle"></div>
              </div>
              <div class="tt-timeline-component timeline-content">
                <h3>Challenges in Developing Future Destinations</h3>
                <p>A Case Study of Puri - a Global Spiritual Hub</p>
                <p>Date: 31st May 2024 (Friday)</p>
              </div>
              <div class="tt-timeline-component timeline-content">
                <h3>Exploring Tomorrow</h3>
                <p>Unveiling Opportunities in Future Tourism</p>
                <p>Date: 7th June 2024 (Friday)</p>
              </div>
              <div class="timeline-middle">
                <div class="timeline-circle"></div>
              </div>
              <div class="timeline-empty">
              </div>

              <div class="timeline-empty">
              </div>

              <div class="timeline-middle">
                <div class="timeline-circle"></div>
              </div>
              <div class="tt-timeline-component timeline-content">
                <h3>Building Enterprises, Building Bharat</h3>
                <p>India as a Global Destination and Future-Ready Enterprises</p>
                <p>Date: 25th June 2024 (Tuesday)</p>
              </div>
              <div class="tt-timeline-component timeline-content">
                <h3>Solving Challenges Together</h3>
                <p>Fostering Mutual Growth Among Youth-Led Enterprises in India</p>
                <p>Date: 28th June 2024 (Friday)</p>
              </div>
              <div class="timeline-middle">
                <div class="timeline-circle"></div>
              </div>
            </div>
          </section>
          {/* <div className="leaf-contents">
            <img src="assets/img/leaf/_ (1).png" alt="" />
            <img src="assets/img/leaf/_ (2).png" alt="" />
            <img src="assets/img/leaf/_ (3).png" alt="" />
            <img src="assets/img/leaf/_ (4).png" alt="" />
            <img src="assets/img/leaf/_ (4).png" alt="" />
            <img src="assets/img/leaf/_ (3).png" alt="" />
            <img src="assets/img/leaf/_ (2).png" alt="" />
            <img src="assets/img/leaf/_ (1).png" alt="" />
          </div> */}
        </div >
      </motion.div>

      <motion.div className="speakers"
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
      >
        <h1>Speakers</h1>
        <div className="speaker-container">
          <div className="speaker-obj">
            <img src="assets/img/speaker_images/joy_jagdish.jpg" alt="" />
            <div className="speaker-detail-container">
              <h3>Mr. Joy Jagadish Patra</h3>
              <p>Senior Manager</p>
              <p>Cloudbeds</p>
            </div>
          </div>
          <div className="speaker-obj">
            <img src="assets/img/speaker_images/anant prasad main.png" alt="" />
            <div className="speaker-detail-container">
              <h3>Mr. Ananta Prasad</h3>
              <p>Founder & Director</p>
              <p>Mutt Packers</p>
            </div>
          </div>
          <div className="speaker-obj">
            <img src="assets/img/speaker_images/biswajit_khuntia.jpg" alt="" />
            <div className="speaker-detail-container">
              <h3>Mr. Biswajeet Khuntia</h3>
              <p>Director</p>
              <p>Claimmyshares</p>
            </div>
          </div>
          <div className="speaker-obj">
            <img src="assets/img/speaker_images/pravat_panda.jpg" alt="" />
            <div className="speaker-detail-container">
              <h3>Mr. Pravat Panda</h3>
              <p>Founder</p>
              <p>Retrod</p>
            </div>
          </div>
          <div className="speaker-obj">
            <img src="assets/img/speaker_images/Dr_anjuna_Dhir.png" alt="" />
            <div className="speaker-detail-container">
              <h3>Dr. Anjuna Dhir</h3>
              <p>Partner</p>
              <p>Destinopedia Advisors</p>
            </div>
          </div>
          <div className="speaker-obj">
            <img src="assets/img/speaker_images/Kamakshi_Mahesh.png" alt="" />
            <div className="speaker-detail-container">
              <h3>Dr. Kamakshi Maheshwari</h3>
              <p>Assistant Professor</p>
              <p>IITTM
                Nodal Officer - CNA-RT, <br/>Ministry of Tourism, <br/>Govt. of India</p>
            </div>
          </div>
          <div className="speaker-obj">
            <img src="assets/img/speaker_images/Abhishek Singh_ (2).png" alt="" />
            <div className="speaker-detail-container">
              <h3>Mr. Abhishek Singh</h3>
              <p>Incubation Manager</p>
              <p>AIC-GIM Foundation</p>
            </div>
          </div>
          <div className="speaker-obj">
            <img src="assets/img/speaker_images/Shibani Mitra (2).png" alt="" />
            <div className="speaker-detail-container">
              <h3>Ms. Shibani Mitra</h3>
              <p>Program Manager</p>
              <p>AIC - Nalanda</p>
            </div>
          </div>
          <div className="speaker-obj">
            <img src="assets/img/speaker_images/sharad_bansal.png" alt="" />
            <div className="speaker-detail-container">
              <h3>Mr. Sharad Bansal</h3>
              <p>Founder & Managing Partner</p>
              <p>Warmup Ventures</p>
            </div>
          </div>
          <div className="speaker-obj">
            <img src="assets/img/speaker_images/arjun_akruwala.png" alt="" />
            <div className="speaker-detail-container">
              <h3>Mr. Arjun Akruwala</h3>
              <p>Founder</p>
              <p>Rogue Opportunities</p>
            </div>
          </div>
          <div className="speaker-obj">
            <img src="assets/img/speaker_images/manisha_pande.png" alt="" />
            <div className="speaker-detail-container">
              <h3>Ms. Manisha Pande</h3>
              <p>Co-Founder, Managing Director</p>
              <p>Village Ways Pvt. Ltd</p>
            </div>
          </div>
          <div className="speaker-obj">
            <img src="assets/img/speaker_images/meenakshie_mehta.png" alt="" />
            <div className="speaker-detail-container">
              <h3>Ms. Meenakshie Mehta</h3>
              <p>Publisher</p>
              <p>Outlook Publishing (India)</p>
            </div>
          </div>
          <div className="speaker-obj">
            <img src="assets/img/speaker_images/jeet_sing.png" alt="" />
            <div className="speaker-detail-container">
              <h3>Mr. Jeet Singh Arya</h3>
              <p>Founder</p>
              <p>Unexplored Bastar</p>
            </div>
          </div>
          <div className="speaker-obj">
            <img src="assets/img/speaker_images/prem_prasad.png" alt="" />
            <div className="speaker-detail-container">
              <h3>Ms. Prerna Prasad</h3>
              <p>Founder & CEO</p>
              <p>Ecoplore</p>
            </div>
          </div>
          <div className="speaker-obj">
            <img src="assets/img/speaker_images/varun_hegde.png" alt="" />
            <div className="speaker-detail-container">
              <h3>Mr. Varun Hegde</h3>
              <p>Co-founder</p>
              <p>Soul Travelling, Goa</p>
            </div>
          </div>

          {/* <div className="speaker-obj">
            <img src="assets/img/sample_profile.webp" alt="" />
            <div className="speaker-detail-container">
              <h3>Name: Speaker Name</h3>
              <p>Designation: Speaker Designation</p>
              <p>Organization: Speaker Organization</p>
            </div>
          </div> */}
        </div>
      </motion.div>
      <Footer />
    </div >
  );
}

export default TT;
