import logo from './logo.svg';
import './App.css';
import Home from './components/Home/Home';
import TT from './components/TT/TT';
import Header from './components/Header/Header';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FAQ from './components/FAQs/FAQs';
// Usage example:
const questions = [
  { title: 'What is this FAQ section?', content: 'This is a React component for creating a collapsible FAQ section.' },
  { title: 'How does it work?', content: 'It uses React state to track which questions are open and displays/hides content accordingly.' },
  // ... add more questions
];

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/tourism-talk" element={<TT />} exact />
          {/* <Route path="/faq" element={<FAQ questions={questions} />} exact /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
