import React from "react";
import "./WCA.css";
import { motion } from "framer-motion";

const WCA = () => {
  return (
    <div className="wca">
      <motion.div className="wca-content"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
      >
        <div className="wca-inner-content">
          <h1>Eligibility</h1>
          <ul>
            <li>
              <h3>Any Startup India - Recognised Startup, working in the Tourism Domain (irrespective of their sector of operations), at MVP/Early Revenue Stage, looking for Investment & Scale-up Opportunity can enrol for this program.</h3>
            </li>
          </ul>
          <a href="https://forms.gle/DTPFuezfeprY2yDf7" target="_blank">
            <button>Apply Now</button>
          </a>
        </div>
      </motion.div>
      <div className="wca-img">
        <motion.img src="assets/img/eligibility_for web.PNG" alt=""
          initial={{ opacity: 0, filter: "blur(20px)" }}
          whileInView={{ opacity: 1, filter: "blur(0px)" }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
        />
      </div>
    </div>
  );
};

export default WCA;