import React, { useState } from "react";
import "./PO.css";
import { motion } from "framer-motion";

const PO = () => {

    return (
        <div className="po">
            <div className="po-ref">
                <motion.img
                    initial={{ opacity: 0, filter: "blur(20px)" }}
                    whileInView={{ opacity: 1, filter: "blur(0px)" }}
                    transition={{ duration: 0.5, ease: 'easeIn' }}
                    src="assets/img/po_alt.png" alt=""
                />
            </div>
            <motion.div className="po-content"
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, ease: 'easeOut' }}
            >

                <div className="po-inner-content">
                    <h1>Program Offerings</h1>
                    <div className="po-items">
                        <ol>
                            <li>LIVE mentoring sessions by Domain Expert</li>
                            <li>Connect with valuable Business and Industry</li>
                            <li>Investment Raising Opportunities</li>
                            <li>Corporate Startup Support Credits</li>
                            <li>Networking opportunity with Communities</li>
                            <li>Certificate issuance upon program completion</li>
                        </ol>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default PO;