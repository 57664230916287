import React from "react";
import "./About.css";
import { motion } from "framer-motion";

const About = () => {
    return (
        <div className="about">
            <div className="about-img-sec">
                <motion.img src="assets/img/chilika.jpg" alt=""
                    initial={{ opacity: 0, filter: "blur(20px)" }}
                    whileInView={{ opacity: 1, filter: "blur(0px)" }}
                    transition={{ duration: 0.5, ease: 'easeIn' }}
                />
            </div>
            <div className="about-content-sec">
                <motion.div className="about-inner-sec"
                    initial={{ opacity: 0, y: 100 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.2, ease: 'easeIn' }}
                >
                    <h1>About</h1>
                    <p>
                        The Odisha Tourism Acceleration Program (OTAP) is one-of-its-kind initiatives by AIC–Nalanda, specially designed for startups at a early-revenue stage, working in tourism and allied domain. This acceleration program will provide comprehensive support and guidance to the startups through various masterclasses, mentoring sessions and valuable connects with investors and industry stakeholders with an aim to scale-up their businesses.
                    </p>
                </motion.div>
            </div>
        </div>
    );
};

export default About;