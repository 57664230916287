import React from "react";
import "./Timeline.css";
import Icofont from "react-icofont";
import { motion } from "framer-motion";

const Timeline = () => {
    return (
        
        <motion.div className="tl"
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
        >
            <h1>Timeline</h1>
            <div className="tl-content">
                <section class="design-section">
                    <div class="timeline">
                        <div class="timeline-empty">
                        </div>
                        <div class="timeline-middle">
                            <div class="timeline-circle"></div>
                        </div>
                        <div class="timeline-component timeline-content">
                            <h3>Applications Open</h3>
                            <p>29th May 2024</p>
                        </div>
                        <div class="timeline-component timeline-content">
                            <h3>Applications Close</h3>
                            <p>22nd June 2024</p>
                        </div>
                        <div class="timeline-middle">
                            <div class="timeline-circle"></div>
                        </div>
                        <div class="timeline-empty">
                        </div>

                        <div class="timeline-empty">
                        </div>

                        <div class="timeline-middle">
                            <div class="timeline-circle"></div>
                        </div>
                        <div class=" timeline-component timeline-content">
                            <h3>Program Commencement</h3>
                            <p>17th July 2024</p>
                        </div>
                        <div class=" timeline-component timeline-content">
                            <h3>Program Ends</h3>
                            <p>9th November 2024</p>
                        </div>
                        <div class="timeline-middle">
                            <div class="timeline-circle"></div>
                        </div>
                    </div>
                </section>
                <div className="leaf-contents">
                    <img src="assets/img/leaf/_ (1).png" alt="" />
                    <img src="assets/img/leaf/_ (2).png" alt="" />
                    <img src="assets/img/leaf/_ (3).png" alt="" />
                    <img src="assets/img/leaf/_ (4).png" alt="" />
                    <img src="assets/img/leaf/_ (4).png" alt="" />
                    <img src="assets/img/leaf/_ (3).png" alt="" />
                    <img src="assets/img/leaf/_ (2).png" alt="" />
                    <img src="assets/img/leaf/_ (1).png" alt="" />
                </div>
            </div >
        </motion.div>
    )
}

export default Timeline;