import React from "react";
import "./Header.css";
import { motion } from "framer-motion";
import { useLocation, Link } from 'react-router-dom';
const Header = () => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <motion.div className="header"
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: 'easeOut' }}
    >
      <img src="assets/img/AIC_logo_black.webp" alt="" />
      <div className="menu">
        <Link to="/" ><button className={ path=== "/" ? "active": ""}>HOME</button></Link>
        <Link to="/tourism-talk" ><button className={ path=== "/tourism-talk" ? "tt-btn active": "tt-btn"}>TOURISM TALKS</button></Link>
        {/* <Link to="/faq" ><button className={ path=== "/faq" ? "active": ""}>FAQs</button></Link> */}
      </div>
      {/* <div className="apply-section">
        <a href="https://forms.gle/DTPFuezfeprY2yDf7"
        target="_blank">
          <button>APPLY NOW</button>
        </a>
      </div> */}
    </motion.div>
  );
};

export default Header;