import Hero from '../../components/HeroSection/Hero';
import About from '../../components/About/About';
import PS from '../../components/PreferredSectors/PreferredSectors';
import PO from '../../components/ProgramOfferings/ProgramOfferings';
import WCA from '../../components/WCA/WCA';
import Timeline from '../../components/Timeline/Timeline';
import Partners from '../../components/Partners/Partners';
import Footer from '../../components/Footer/Footer';


function Home() {
  return (
    <div className="Home">
      <Hero />
      <About />
      <PS />
      <PO />
      <WCA />
      <Timeline />
      {/* <Partners /> */}
      <Footer />
    </div>
  );
}

export default Home;
