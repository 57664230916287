import React from "react";
import "./Hero.css";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-image-sec">
        <motion.img
          initial={{ opacity: 0, filter: "blur(20px)" }}
          whileInView={{ opacity: 1, filter: "blur(0px)" }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          src="assets/img/otap_logo.png" alt="" />
        <a href="https://forms.gle/DTPFuezfeprY2yDf7" target="_blank"
          style={{ textDecoration: "none", color: "white" }}
        >
          <motion.button
            initial={{ opacity: 0, filter: "blur(20px)" }}
            whileInView={{ opacity: 1, filter: "blur(0px)" }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            Apply Now
          </motion.button>
        </a>
      </div>
      <div className="hero-main-image-sec">
        <motion.img src="assets/img/WIDE.JPG" alt="" className="hero-img"
          initial={{ opacity: 0, filter: "blur(20px)" }}
          whileInView={{ opacity: 1, filter: "blur(0px)" }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
        />
      </div>
    </div>
  );
};

export default Hero;