import React, { useState } from "react";
import { motion } from "framer-motion";
import "./PS.css";


const PS = () => {
    const [activeImage, setActiveImage] = useState(
        {
            "pro": 0,
            "cust": 0,
            "market": 0,
            "business": 0,
            "roadmap": 0,
            "compliance": 0,
            "funding": 0,
            "investors": 0
        }
    );

    return (
        <div className="ps">
            <motion.div className="ps-content"
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, ease: 'easeOut' }}
            >
                <div className="ps-inner-content">
                    <h1>Program Highlights</h1>
                    <div className="ps-items">
                        <ol>
                            <li
                                onMouseEnter={() => setActiveImage({
                                    "pro": 1,
                                    "cust": 0,
                                    "market": 0,
                                    "business": 0,
                                    "roadmap": 0,
                                    "compliance": 0,
                                    "funding": 0,
                                    "investors": 0
                                })}
                            >Product/Service Validation</li>
                            <li
                                onMouseEnter={() => setActiveImage({
                                    "pro": 0,
                                    "cust": 1,
                                    "market": 0,
                                    "business": 0,
                                    "roadmap": 0,
                                    "compliance": 0,
                                    "funding": 0,
                                    "investors": 0
                                })}
                            >Customer Experience</li>
                            <li
                                onMouseEnter={() => setActiveImage({
                                    "pro": 0,
                                    "cust": 0,
                                    "market": 1,
                                    "business": 0,
                                    "roadmap": 0,
                                    "compliance": 0,
                                    "funding": 0,
                                    "investors": 0
                                })}
                            >Market Exploration</li>
                            <li
                                onMouseEnter={() => setActiveImage({
                                    "pro": 0,
                                    "cust": 0,
                                    "market": 0,
                                    "business": 1,
                                    "roadmap": 0,
                                    "compliance": 0,
                                    "funding": 0,
                                    "investors": 0
                                })}
                            >Business Opportunities</li>
                            <li
                                onMouseEnter={() => setActiveImage({
                                    "pro": 0,
                                    "cust": 0,
                                    "market": 0,
                                    "business": 0,
                                    "roadmap": 1,
                                    "compliance": 0,
                                    "funding": 0,
                                    "investors": 0
                                })}
                            >Building Strategic Roadmap</li>
                            <li
                                onMouseEnter={() => setActiveImage({
                                    "pro": 0,
                                    "cust": 0,
                                    "market": 0,
                                    "business": 0,
                                    "roadmap": 0,
                                    "compliance": 1,
                                    "funding": 0,
                                    "investors": 0
                                })}
                            >IP & Legal Compliance</li>
                            <li
                                onMouseEnter={() => setActiveImage({
                                    "pro": 0,
                                    "cust": 0,
                                    "market": 0,
                                    "business": 0,
                                    "roadmap": 0,
                                    "compliance": 0,
                                    "funding": 1,
                                    "investors": 0
                                })}
                            >Equity & Funding Strategy</li>
                            <li
                                onMouseEnter={() => setActiveImage({
                                    "pro": 0,
                                    "cust": 0,
                                    "market": 0,
                                    "business": 0,
                                    "roadmap": 0,
                                    "compliance": 0,
                                    "funding": 0,
                                    "investors": 1
                                })}
                            >Demo Day with Investors</li>
                        </ol>
                    </div>
                </div>
            </motion.div>
            <div className="ps-ref">
                <motion.img src={`assets/img/PS_Imgs/bussiness_op.png`} alt="ps-ref"
                    initial={{ opacity: 0, filter: "blur(20px)" }}
                    whileInView={{ opacity: 1, filter: "blur(0px)" }}
                    transition={{ duration: 0.5, ease: 'easeOut' }}
                    className={`ps-img ${activeImage["business"] ? "shadow-scale" : ""}`}
                    style={{ top: "10%", left: "20%", transform: "rotate(20deg)", padding: "20px" }}
                />
                <motion.img src="assets/img/PS_Imgs/customer_exp.png" alt="ps-ref"
                    initial={{ opacity: 0, filter: "blur(20px)" }}
                    whileInView={{ opacity: 1, filter: "blur(0px)" }}
                    transition={{ duration: 0.5, ease: 'easeOut' }}
                    className={`ps-img ${activeImage["cust"] ? "shadow-scale" : ""}`}
                    style={{ top: "8%", left: "40%", transform: "rotate(-20deg)", padding: "20px" }}
                />
                <motion.img src="assets/img/PS_Imgs/demo_day.png" alt="ps-ref"
                    initial={{ opacity: 0, filter: "blur(20px)" }}
                    whileInView={{ opacity: 1, filter: "blur(0px)" }}
                    transition={{ duration: 0.5, ease: 'easeOut' }}
                    className={`ps-img ${activeImage['investors'] ? "shadow-scale" : ""}`}
                    style={{ top: "10%", left: "60%", transform: "rotate(7deg)", padding: "20px" }}
                />
                <motion.img src="assets/img/PS_Imgs/funding.png" alt="ps-ref"
                    initial={{ opacity: 0, filter: "blur(20px)" }}
                    whileInView={{ opacity: 1, filter: "blur(0px)" }}
                    transition={{ duration: 0.5, ease: 'easeOut' }}
                    className={`ps-img ${activeImage['funding'] ? "shadow-scale" : ""}`}
                    style={{ top: "40%", left: "18%", transform: "rotate(30deg)", padding: "20px" }}
                />
                <motion.img src="assets/img/PS_Imgs/legal_ip.png" alt="ps-ref"
                    initial={{ opacity: 0, filter: "blur(20px)" }}
                    whileInView={{ opacity: 1, filter: "blur(0px)" }}
                    transition={{ duration: 0.5, ease: 'easeOut' }}
                    className={`ps-img ${activeImage['compliance'] ? "shadow-scale" : ""}`}
                    style={{ top: "40%", left: "40%", transform: "rotate(-10deg)", padding: "20px" }}
                />
                <motion.img src="assets/img/PS_Imgs/market_exploration.png" alt="ps-ref"
                    initial={{ opacity: 0, filter: "blur(20px)" }}
                    whileInView={{ opacity: 1, filter: "blur(0px)" }}
                    transition={{ duration: 0.5, ease: 'easeOut' }}
                    className={`ps-img ${activeImage['market'] ? "shadow-scale" : ""}`}
                    style={{ top: "40%", left: "62%", transform: "rotate(7deg)", padding: "20px" }}
                />
                <motion.img src="assets/img/PS_Imgs/pro_valid.png" alt="ps-ref"
                    initial={{ opacity: 0, filter: "blur(20px)" }}
                    whileInView={{ opacity: 1, filter: "blur(0px)" }}
                    transition={{ duration: 0.5, ease: 'easeOut' }}
                    className={`ps-img ${activeImage['pro'] ? "shadow-scale" : ""}`}
                    style={{ top: "65%", left: "25%", transform: "rotate(18deg)", padding: "20px" }}
                />
                <motion.img src="assets/img/PS_Imgs/strategic.png" alt="ps-ref"
                    initial={{ opacity: 0, filter: "blur(20px)" }}
                    whileInView={{ opacity: 1, filter: "blur(0px)" }}
                    transition={{ duration: 0.5, ease: 'easeOut' }}
                    className={`ps-img ${activeImage['roadmap'] ? "shadow-scale" : ""}`}
                    style={{ top: "65%", left: "50%", transform: "rotate(-18deg)", padding: "20px" }}
                />
            </div>
        </div>
    );
};

export default PS;